import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';
import { SkipSelf } from '@angular/core';
import { Optional } from '@angular/core';
import { Host } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormArrayName, FormGroupName, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'np-fcv-error',
  templateUrl: './np-fcv-error.component.html',
  styleUrls: ['./np-fcv-error.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class NpFcvErrorComponent implements OnInit {

  @Input('name') name: string | Number | any;
  @Input('text') text: string | any;
  @Input('errorMsg') errorMsg: Object | any;


  @Input('multiple') multiple: boolean = false;

  _parent: ControlContainer | null = null;
  // _ngForm: NgForm | null = null;

  private supportParentClassType: string[] = ['NgForm', 'FormGroupDirective', 'FormGroupName'];
  constructor(
    private _ngEl: ElementRef,
    private _renderer: Renderer2,
    @Optional() @Host() @SkipSelf() parent: ControlContainer) {

    if (!parent || !this.supportParentClassType.includes(parent.constructor.name)) {
      // console.log('need check type of parent - ');
      // console.log('need check type of parent - ' + parent.constructor.name);
    }

    this._parent = parent;
  }

  ngOnInit(): void {
  }

  public get control(): AbstractControl | null {
    if (!this._parent) {
      return null;
    }
    if (!this._parent.control) {
      return null;
    }
    return this._parent.control.get(this.name.toString());
  }

  chkSubmitted() {
    let form: any = this._parent;
    if (this._parent instanceof FormGroupName) {
      // FormGroupName
      return form._parent.submitted;
    } else if (this._parent instanceof FormArrayName) {
      return form._parent.submitted;
    } else {
      // NgForm, FormGroupDirective
      return form.submitted;
    }
  }

  public get errors() {

    if (!this.control) {
      return null;
    }

    if (!this.control.invalid) {
      return null;
    }

    if (!this.chkSubmitted() && (!this.control.dirty && !this.control.touched)) {
      return null;
    }

    if (!this.control.errors) {
      return null;
    }

    let errors: ValidationErrors = this.control.errors;

    let messages = [];

    if (this.multiple === false) {
      let validation = Object.keys(errors)[0];
      let data = errors[validation];
      let message = this.getErrorMessage(validation, data);
      messages.push(message);
    } else {

      Object.keys(errors).forEach((validation) => {
        let data = errors[validation];
        let message = this.getErrorMessage(validation, data);
        messages.push(message);
      })
    }

    return messages;
  }

  getErrorMessage(validation: any, data: any) {

    if (this.errorMsg && this.errorMsg[validation]) {
      return this.errorMsg[validation];
    }

    if ((this.defaultErrorMsgHelper as any)[validation]) {
      return (this.defaultErrorMsgHelper as any)[validation](data);
    }

    return validation;
  }

  defaultErrorMsgHelper = {
    required: (data: any) => {
      return `필수입력 항목입니다.`;
    },
    email: (data: any) => {
      return `올바른 이메일 주소를 입력하세요.`;
    },
    minlength: (data: any) => {
      // {requiredLength: 7, actualLength: 4}
      return `최소 ${data.requiredLength}자 이상 입력하세요.`;
    },
    maxlength: (data: any) => {
      // {requiredLength: 20, actualLength: 48}
      return `최대 ${data.requiredLength}자 이하로 입력하세요.`;
    },
    npErrorMessage: (data : any) => {
      return `${data}`;
    }

  }

}
