<ng-container *ngFor="let errMsg of errors">
    <div class="np-fcv-error">
        {{errMsg}}
    </div>
</ng-container>

<!-- <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
    validation: 'required',
    message: 'Password is required',
    control: form1.controls['loginId']
  }">
</ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    </ng-container>
</ng-template> -->